import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createMockRequest } from "../utils";
import { SETTINGS_MOCK, STRATEGY_LIST_MOCK, USERS_MOCK } from "./mock.data";
import { requestBacktest } from "./mock.api";

export const useStrategyListData = () =>
  useSuspenseQuery({
    queryKey: ["strategy"],
    queryFn: createMockRequest(() => STRATEGY_LIST_MOCK),
  });

export const useStrategyData = (id: number) =>
  useSuspenseQuery({
    queryKey: ["strategy", id],
    queryFn: createMockRequest(() =>
      STRATEGY_LIST_MOCK.find((x) => x.id === id),
    ),
  });

export const useBacktestData = (id: string) =>
  useSuspenseQuery({
    queryKey: ["backtest", id],
    queryFn: createMockRequest(() => requestBacktest(id)),
  });

export const useSettingsData = () =>
  useSuspenseQuery({
    queryKey: ["settings"],
    queryFn: createMockRequest(() => SETTINGS_MOCK),
  });

export const useUsers = () =>
  useQuery({
    queryKey: ["users"],
    queryFn: createMockRequest(() => USERS_MOCK),
  });

export const useUser = (id: number) => {
  const { data, isLoading } = useUsers();
  if (isLoading || !data) return undefined;
  return data.find((user) => user.id === id);
};
