import { FC, useMemo } from "react";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import BugReportIcon from "@mui/icons-material/BugReport";
import LineAxisIcon from "@mui/icons-material/LineAxis";

import HomePage from "../Page/HomePage";
import SettingsPage from "../Page/SettingsPage";
import StrategyPage from "../Page/StrategyPage/StrategyPage";
import TestPage from "../Page/TestPage";
import UsersPage from "../Page/UsersPage/UsersPage";
import WalletsPage from "../Page/WalletsPage";
import MaXMaPage from "../Page/MaXMa/MaXMaPage";
import MaXMaTaskPage from "../Page/MaXMa/MaXMaTaskPage";
import MaXMaOrderPage from "../Page/MaXMa/MaXMaOrderPage";
import MaXMaTaskChartPage from "../Page/MaXMa/MaXMaTaskChartPage";

type Route = {
  name: string;
  redirect?: string;
  path: string;
  element: FC;
  menu: FC | null;
};

export const routes: Route[] = [
  {
    name: "home",
    path: "/",
    redirect: "/maxma/",
    element: HomePage,
    menu: HomeRoundedIcon,
  },
  {
    name: "strategy",
    path: "/strategy/:id",
    element: StrategyPage,
    menu: null,
  },
  {
    name: "MA x MA",
    path: "/maxma/",
    element: MaXMaPage,
    menu: LineAxisIcon,
  },
  {
    name: "MA x MA - Task",
    path: "/maxma/task/:task_id",
    element: MaXMaTaskPage,
    menu: null,
  },
  {
    name: "MA x MA - Chart",
    path: "/maxma/task/:task_id/chart",
    element: MaXMaTaskChartPage,
    menu: null,
  },
  {
    name: "MA x MA - Order",
    path: "/maxma/order/:order_id",
    element: MaXMaOrderPage,
    menu: null,
  },
  {
    name: "settings",
    path: "/settings",
    element: SettingsPage,
    menu: null,
  },
  {
    name: "users",
    path: "/users",
    element: UsersPage,
    menu: null,
  },
  {
    name: "wallets",
    path: "/wallets",
    element: WalletsPage,
    menu: null,
  },
  {
    name: "test",
    path: "/test",
    element: TestPage,
    menu: BugReportIcon,
  },
];

const getRouteByName = (name: string) => {
  const result = routes.find((r) => r.name === name);
  if (!result) throw Error();
  return result;
};

export const useRoute = (name: string) =>
  useMemo(() => getRouteByName(name), [name]);
