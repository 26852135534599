import {
  Button,
  CircularProgress,
  ColorPaletteProp,
  Modal as JoyModal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { PropsWithChildren, useState } from "react";
import { delay, random } from "../utils";

type Props = {
  title: string;
  actionButtonText?: string;
  actionButtonColor?: ColorPaletteProp;
  open: boolean;
  onClose: () => void;
};
export default function Modal(props: PropsWithChildren<Props>) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <JoyModal
      open={props.open}
      onClose={!isLoading ? props.onClose : undefined}
    >
      <ModalDialog sx={{ minWidth: 320 }}>
        <ModalClose disabled={isLoading} sx={{ m: 1 }} />
        <Typography level="title-md">{props.title}</Typography>
        {props.children}
        <Button
          color={props.actionButtonColor}
          onClick={() => {
            setIsLoading(true);
            delay(500 + random(1000))
              .then(() => setIsLoading(false))
              .then(props.onClose);
          }}
        >
          {isLoading ? (
            <CircularProgress color="neutral" />
          ) : (
            props.actionButtonText || "Update"
          )}
        </Button>
      </ModalDialog>
    </JoyModal>
  );
}
