import { Kline } from "../../../../crypto-bulls-backend/src/components/history/history.type";
import { ChartCandleData, ChartLineData } from "./chart.type";

export const useCandleData = (data: Kline[]) => {
  const candleData: ChartCandleData[] = data.map((d) => ({
    ts: d.ts.open,
    open: d.price.open,
    high: d.price.high,
    low: d.price.low,
    close: d.price.close,
    volume: d.volume,
  }));

  const lineData: ChartLineData[] = candleData.map((d) => ({
    ts: d.ts,
    value: d.close,
  }));

  return {
    candleData,
    lineData,
  };
};
