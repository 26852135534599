import { Box, Card, Typography } from "@mui/joy";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import { ChartProps } from "./chart.type";

type Props = {
  series: ChartProps["series"];
};

export default function Legend({ series }: Props) {
  return (
    <Card variant="outlined" orientation="horizontal" size="sm" sx={{ gap: 2 }}>
      {series.map((s, i) => (
        <Box key={i} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              overflow: "hidden",
              background: s.color,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!s.color && <CandlestickChartIcon fontSize="large" />}
          </Box>
          <Typography>{s.name}</Typography>
        </Box>
      ))}
    </Card>
  );
}
