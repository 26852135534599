import { createContext, PropsWithChildren, useState } from "react";

export type BreadcrumbsItem = { text: string; href?: string };
export type TBreadcrumbsContext = {
  breadcrumbs: BreadcrumbsItem[];
  setBreadcrumbs: (value: BreadcrumbsItem[]) => void;
};

export const BreadcrumbsContext = createContext<TBreadcrumbsContext>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
});

export function BreadcrumbsProvider(props: PropsWithChildren) {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItem[]>([]);
  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {props.children}
    </BreadcrumbsContext.Provider>
  );
}
