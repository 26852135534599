import { PropsWithChildren, useMemo } from "react";
import { useLocation } from "react-router-dom";
import AuthPage from "../Page/AuthPage";
import { LS_AUTH_KEY } from "../const";

export default function Auth(props: PropsWithChildren) {
  const location = useLocation();

  const authKey = useMemo(
    () => localStorage.getItem(LS_AUTH_KEY),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.key],
  );

  if (authKey) {
    return <>{props.children}</>;
  }
  return <AuthPage />;
}
