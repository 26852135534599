import { Sheet } from "@mui/joy";
import UserCard from "../../Components/UserCard";
import Modal from "../../Components/Modal";

type Props = {
  open: boolean;
  id: number;
  onClose: () => void;
};

export default function DeleteUserConfirmationDialog(props: Props) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      title="Confirm delete user"
      actionButtonText="Delete"
      actionButtonColor="danger"
    >
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: 1,
          borderRadius: "sm",
        }}
      >
        <UserCard size="lg" id={props.id} />
      </Sheet>
    </Modal>
  );
}
