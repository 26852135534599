import { Box, CircularProgress, CircularProgressTypeMap } from "@mui/joy";

type Props = {
  size?: CircularProgressTypeMap["props"]["size"];
};

const Spinner = ({ size = "lg" }: Props) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <CircularProgress size={size} />
  </Box>
);

export default Spinner;
