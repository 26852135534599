import {
  Box,
  LinearProgress,
  List,
  ListItemButton,
  Typography,
} from "@mui/joy";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { fixJsFloat } from "../utils";
import { generatePath, useNavigate } from "react-router-dom";
import { useRoute } from "../Layout/router.const";

type Props = {
  data: { id: number; name: string; progress: number; profit: number }[];
};

export default function BacktestList({ data }: Props) {
  const navigate = useNavigate();
  const backtestPageRoute = useRoute("backtest");
  return (
    <List>
      {data.map((iteration) => (
        <ListItemButton
          key={iteration.id}
          onClick={() =>
            navigate(
              generatePath(backtestPageRoute.path, { id: "" + iteration.id }),
            )
          }
        >
          <Typography noWrap color="neutral">
            {iteration.id}
          </Typography>
          <Typography noWrap>{iteration.name}</Typography>
          <Box flex={1} />
          <Box>
            {iteration.progress === 1 ? (
              <Typography
                noWrap
                sx={{ display: "flex" }}
                color={iteration.profit < 0 ? "danger" : "success"}
              >
                {iteration.profit > 0 && <ArrowDropUpIcon />}
                {iteration.profit < 0 && <ArrowDropDownIcon />}
                {Math.abs(fixJsFloat(iteration.profit * 10)).toFixed(2)}%
              </Typography>
            ) : (
              <LinearProgress
                size="lg"
                determinate
                value={iteration.progress * 100}
                sx={{ width: 70 }}
              />
            )}
          </Box>
        </ListItemButton>
      ))}
    </List>
  );
}
