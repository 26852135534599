import { useContext } from "react";
import { Box, Breadcrumbs as JoyBreadcrumbs, Link, Typography } from "@mui/joy";

import HomeIcon from "@mui/icons-material/Home";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { useRoute } from "../../Layout/router.const";
import { BreadcrumbsContext } from "./BreadcrumbsContext";

const Breadcrumbs = () => {
  const homeRoute = useRoute("home");
  const { breadcrumbs } = useContext(BreadcrumbsContext);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <JoyBreadcrumbs
        size="sm"
        aria-label="breadcrumbs"
        separator={<ChevronRightRoundedIcon fontSize="small" />}
        sx={{ pl: 0 }}
      >
        <Link
          underline="none"
          color="neutral"
          href={homeRoute.path}
          aria-label="Home"
        >
          <HomeIcon />
        </Link>
        {breadcrumbs.map((parent, i) =>
          parent.href ? (
            <Link
              key={i}
              underline="hover"
              color="neutral"
              href={parent.href}
              sx={{ fontSize: 12, fontWeight: 500 }}
            >
              {parent.text}
            </Link>
          ) : (
            <Typography
              key={i}
              color="primary"
              sx={{ fontSize: 12, fontWeight: 500 }}
            >
              {parent.text}
            </Typography>
          ),
        )}
      </JoyBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
