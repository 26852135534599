import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { useUser } from "../../mock/mock.hooks";
import Modal from "../../Components/Modal";

type Props = {
  open: boolean;
  id?: number;
  onClose: () => void;
};

export default function ConfigureUserDialog(props: Props) {
  const user = useUser(props.id || -1);
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      title={props.id === undefined ? "New user" : `User (ID: ${props.id})`}
    >
      <Typography level="title-md"></Typography>
      <FormControl>
        <FormLabel sx={{ fontSize: 12 }}>First name</FormLabel>
        <Input variant="soft" size="sm" defaultValue={user?.first_name} />
      </FormControl>
      <FormControl>
        <FormLabel sx={{ fontSize: 12 }}>Last name</FormLabel>
        <Input variant="soft" size="sm" defaultValue={user?.last_name} />
      </FormControl>
      <FormControl>
        <FormLabel sx={{ fontSize: 12 }}>E-mail</FormLabel>
        <Input variant="soft" size="sm" defaultValue={user?.email} />
      </FormControl>
      <FormControl>
        <FormLabel sx={{ fontSize: 12 }}>Role</FormLabel>
        <Select size="sm" defaultValue={user?.role}>
          <Option value="admin" disabled>
            Admin
          </Option>
          <Option value="collaborator">Collaborator</Option>
          <Option value="guest">Guest</Option>
        </Select>
      </FormControl>
      {props.id !== undefined && (
        <Button variant="plain" color="neutral">
          Reset password
        </Button>
      )}
    </Modal>
  );
}
