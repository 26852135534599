import { Button, ButtonGroup, List, ListItem } from "@mui/joy";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUsers } from "../../mock/mock.hooks";
import { useRoute } from "../../Layout/router.const";
import { capitalizeFirstLetter } from "../../utils";
import { useState } from "react";
import UserCard from "../../Components/UserCard";
import DeleteUserConfirmationDialog from "./DeleteUserConfirmationDialog";
import Spinner from "../../Components/Spinner";
import ConfigureUserDialog from "./ConfigureUserDialog";
import SimplePageLayout from "../../Layout/SimplePageLayout";

export default function UsersPage() {
  const [isModalDeleteUserOpen, setIsModalDeleteUserOpen] = useState<
    number | null
  >(null);

  const [isModalConfigureUserOpen, setIsModalConfigureUserOpen] = useState<
    number | undefined | null
  >(null);

  const settingsRoute = useRoute("settings");
  const { data } = useUsers();
  return (
    <SimplePageLayout
      title="Users"
      breadcrumbs={[
        {
          text: capitalizeFirstLetter(settingsRoute.name),
          href: settingsRoute.path,
        },
      ]}
      rightTitle={
        <Button
          variant="plain"
          color="neutral"
          onClick={() => setIsModalConfigureUserOpen(undefined)}
        >
          Add
        </Button>
      }
    >
      {data ? (
        <List>
          {data.map((user) => (
            <ListItem>
              <UserCard id={user.id} size="lg" />
              <ButtonGroup variant="plain" disabled={user.role === "admin"}>
                <Button onClick={() => setIsModalConfigureUserOpen(user.id)}>
                  <SettingsIcon />
                </Button>
                <Button onClick={() => setIsModalDeleteUserOpen(user.id)}>
                  <DeleteIcon />
                </Button>
              </ButtonGroup>
            </ListItem>
          ))}
        </List>
      ) : (
        <Spinner />
      )}
      <DeleteUserConfirmationDialog
        open={isModalDeleteUserOpen !== null}
        id={isModalDeleteUserOpen || 0}
        onClose={() => setIsModalDeleteUserOpen(null)}
      />
      <ConfigureUserDialog
        open={isModalConfigureUserOpen !== null}
        id={isModalConfigureUserOpen === null ? 0 : isModalConfigureUserOpen}
        onClose={() => setIsModalConfigureUserOpen(null)}
      />
    </SimplePageLayout>
  );
}
