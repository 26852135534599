import { BacktestOrderEntity } from "../../../../crypto-bulls-backend/src/components/backtest/backtest.entity";
import { Timeframe } from "../../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { useMarketHistory } from "../../api/binance.spot.api";
import { sma } from "../../indicator";
import { MS_IN_TIMEFRAME } from "../../timeframe";
import Chart from "./Chart";
import Spinner from "../Spinner";
import { useCandleData } from "./hooks";

const CHART_SIDE_FACTOR = 0.1;
const MIN_CANDLE_WIDTH = 64;

type Props = {
  symbol: string;
  tf: string;
  type: BacktestOrderEntity["type"];
  enter: {
    ts: number;
    price: number;
  };
  exit: {
    ts: number;
    price: number;
  };
  sma: {
    a: number;
    b: number;
  };
};

export default function OrderChart({
  symbol,
  tf,
  type,
  enter,
  exit,
  sma: smaValues,
}: Props) {
  const dataCandleWidth = (exit.ts - enter.ts) / MS_IN_TIMEFRAME[tf];
  const minDataOffset =
    (Math.max(MIN_CANDLE_WIDTH - dataCandleWidth, 0) / 2) * MS_IN_TIMEFRAME[tf];
  const dataOffset =
    minDataOffset + (exit.ts - enter.ts) * CHART_SIDE_FACTOR + 0;
  const maxSma = Math.max(smaValues.a, smaValues.b);

  const { data, isLoading } = useMarketHistory(
    symbol,
    tf as Timeframe,
    enter.ts - dataOffset - maxSma * MS_IN_TIMEFRAME[tf],
    exit.ts + dataOffset,
  );

  const { candleData, lineData } = useCandleData(data || []);

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <Chart
      spliceLeft={maxSma}
      series={[
        {
          name: symbol,
          data: candleData,
        },
        {
          name: `sma(${smaValues.a})`,
          color: "#F8AE54",
          data: sma(lineData, smaValues.a),
        },
        {
          name: `sma(${smaValues.b})`,
          color: "#92C5F9",
          data: sma(lineData, smaValues.b),
        },
      ]}
      shapes={[
        {
          type: type === "long" ? "up" : "down",
          x: enter.ts,
          y: enter.price,
        },
        {
          type: type === "long" ? "down" : "up",
          x: exit.ts,
          y: exit.price,
        },
      ]}
    />
  );
}
