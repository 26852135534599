import { useMemo } from "react";
import { Box, Option, Select, Typography } from "@mui/joy";

import { useMarkets } from "../api/binance.spot.api";
import MarketIcon from "./MarketIcon";
import Spinner from "./Spinner";

const AVAILABLE_COINS = [
  "BTC",
  "ETH",
  "BNB",
  "SOL",
  "XRP",
  "DOGE",
  "TRX",
  "TON",
];

type Props = {
  value: string | null;
  onSelect: (value: string | null) => void;
  variant?: React.ComponentProps<typeof Select>["variant"];
};

export default function MarketSelect(props: Props) {
  const markets = useMarkets();

  const selectedMarket = useMemo(
    () => markets.data?.find((x) => x.symbol === props.value),
    [markets.data, props.value],
  );

  return (
    <Select
      sx={{ width: 300 }}
      slotProps={{
        listbox: {
          sx: { width: 300 },
        },
      }}
      placeholder="symbol"
      variant={props.variant || "plain"}
      value={props.value}
      color={markets.isError ? "danger" : "neutral"}
      onChange={(e, v) => props.onSelect(v)}
      endDecorator={
        selectedMarket &&
        selectedMarket.baseAsset &&
        selectedMarket.quoteAsset && (
          <MarketIcon
            size="sm"
            baseAsset={selectedMarket?.baseAsset}
            quoteAsset={selectedMarket?.quoteAsset}
          />
        )
      }
    >
      {markets.isLoading && (
        <Box sx={{ p: 4 }}>
          <Spinner size="md" />
        </Box>
      )}
      {markets.data &&
        !markets.isError &&
        markets.data
          .filter(
            (m) =>
              m.quoteAsset === "USDT" &&
              AVAILABLE_COINS.includes(m.baseAsset || ""),
          )
          .map((market) => (
            <Option key={market.id} value={market.symbol}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight="lg">{market.symbol}</Typography>
                {market.baseAsset && market.quoteAsset && (
                  <MarketIcon
                    baseAsset={market.baseAsset}
                    quoteAsset={market.quoteAsset}
                  />
                )}
              </Box>
            </Option>
          ))}
    </Select>
  );
}
