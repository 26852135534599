import { ShapeChartProps } from "./chart.type";

export type ChartIcon = {
  data: Record<"dark" | "light", string>;
  size: number;
  offsetY?: number;
};

/**
 * https://coolors.co/palettes/trending/bright
 * https://www.svgrepo.com/
 * https://base64.guru/converter/encode/image/svg
 */

export const SHAPES: Record<ShapeChartProps["type"], ChartIcon> = {
  up: {
    data: {
      dark: "PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTEzIDlIMTBWMTZINlY5TDMgOVY4TDggM0wxMyA4VjlaIiBmaWxsPSIjZmZmIi8+IDxwYXRoIGQ9Ik0xNCAySDJWMEgxNFYyWiIgZmlsbD0iI2ZmZiIvPiA8L2c+Cg08L3N2Zz4=",
      light:
        "PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMyA5SDEwVjE2SDZWOUwzIDlWOEw4IDNMMTMgOFY5WiIgZmlsbD0iIzAwMDAwMCIvPg0KPHBhdGggZD0iTTE0IDJIMlYwSDE0VjJaIiBmaWxsPSIjMDAwMDAwIi8+DQo8L3N2Zz4=",
    },
    size: 24,
    offsetY: 12,
  },
  down: {
    data: {
      dark: "PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTEzIDdIMTBWMEg2VjdMMyA3VjhMOCAxM0wxMyA4VjdaIiBmaWxsPSIjZmZmIi8+IDxwYXRoIGQ9Ik0xNCAxNEgyVjE2SDE0VjE0WiIgZmlsbD0iI2ZmZiIvPiA8L2c+Cg08L3N2Zz4=",
      light:
        "PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMyA3SDEwVjBINlY3TDMgN1Y4TDggMTNMMTMgOFY3WiIgZmlsbD0iIzAwMDAwMCIvPg0KPHBhdGggZD0iTTE0IDE0SDJWMTZIMTRWMTRaIiBmaWxsPSIjMDAwMDAwIi8+DQo8L3N2Zz4=",
    },
    size: 24,
    offsetY: -12,
  },
};
