import { generatePath, useNavigate } from "react-router-dom";
import { ButtonGroup, IconButton, Typography } from "@mui/joy";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useBacktestOrder } from "../../api/backtest.api";
import OrderChart from "../../Components/Chart/OrderChart";
import { useRoute } from "../../Layout/router.const";
import SimplePageLayout from "../../Layout/SimplePageLayout";
import { generateTaskName } from "./maxma.utils";

export default function MaXMaOrderPage() {
  const navigate = useNavigate();

  const parentPage = useRoute("MA x MA");
  const taskPage = useRoute("MA x MA - Task");
  const orderPage = useRoute("MA x MA - Order");

  const { data } = useBacktestOrder();

  if (!data) return <Typography>404</Typography>;

  const breadcrumbs = [{ text: parentPage.name, href: parentPage.path }];

  breadcrumbs.push({
    text: generateTaskName(data.task),
    href: generatePath(taskPage.path, { task_id: data?.task.id }),
  });

  // TODO: ограничить переход в ордера соседних тасков

  return (
    <SimplePageLayout
      title={`Order (ID: ${data?.order.id})`}
      breadcrumbs={breadcrumbs}
      contentPadding={0}
      rightTitle={
        <ButtonGroup>
          <IconButton
            onClick={() =>
              navigate(
                generatePath(orderPage.path, { order_id: data.order.id - 1 }),
              )
            }
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton>
            <ChevronRightIcon
              onClick={() =>
                navigate(
                  generatePath(orderPage.path, { order_id: data.order.id + 1 }),
                )
              }
            />
          </IconButton>
        </ButtonGroup>
      }
      bottomTitle={
        data?.order && (
          <Typography
            level="body-sm"
            fontWeight={600}
            color={data.order.profit < 0 ? "danger" : "success"}
          >
            Total: {(data.order.profit * 100).toFixed(2)}%
          </Typography>
        )
      }
    >
      {data && (
        <OrderChart
          symbol={data.task.symbol}
          tf={data.task.timeframe}
          type={data.order.type}
          enter={{ ts: data.order.tsOpen, price: data.order.entryPrice }}
          exit={{ ts: data.order.tsClose, price: data.order.exitPrice }}
          sma={{ a: data.task.smaIntervalA, b: data.task.smaIntervalB }}
        />
      )}
    </SimplePageLayout>
  );
}
