import { Avatar, Box, Skeleton, Typography, TypographySystem } from "@mui/joy";
import { useUser } from "../mock/mock.hooks";

type Props = {
  id: number;
  size?: "sm" | "md" | "lg";
};

const SIZE_MAP = {
  ICON: {
    sm: 32,
    md: 40,
    lg: 48,
  },
  TITLE: {
    sm: "title-xs" as keyof TypographySystem,
    md: "title-sm" as keyof TypographySystem,
    lg: "title-md" as keyof TypographySystem,
  },
  ROLE: {
    sm: "body-xs" as keyof TypographySystem,
    md: "body-xs" as keyof TypographySystem,
    lg: "body-sm" as keyof TypographySystem,
  },
};

export default function UserCard({ id, size = "md" }: Props) {
  const user = useUser(id);
  return (
    <>
      <Skeleton
        variant="circular"
        loading={!user}
        width={SIZE_MAP.ICON[size]}
        height={SIZE_MAP.ICON[size]}
      >
        <Avatar
          size={size}
          src={"https://avatar.iran.liara.run/public/" + user?.id}
        />
      </Skeleton>
      <Box sx={{ minWidth: 0, flex: 1 }}>
        <Typography level={SIZE_MAP.TITLE[size]}>
          <Skeleton loading={!user}>
            {user ? `${user.first_name} ${user.last_name}` : "Vasya Pupkin"}
          </Skeleton>
        </Typography>
        <Typography level={SIZE_MAP.ROLE[size]}>
          <Skeleton loading={!user}>
            {user ? user.role : "motherfucker"}
          </Skeleton>
        </Typography>
      </Box>
    </>
  );
}
