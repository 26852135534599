import { ChartLineData } from "./Components/Chart/chart.type";

export const sma = (data: ChartLineData[], interval: number) =>
  data.map((d, i, arr) => {
    const start = Math.max(i + 1 - interval, 0);
    const end = i + 1;

    const slice = arr.slice(start, end);
    const avg =
      slice.map((x) => x.value).reduce((acc, val) => acc + val, 0) /
      slice.length;

    return { ...d, value: avg };
  });

export const ema = (data: ChartLineData[], interval: number) => {
  const emaArray = [{ ...data[0] }];
  const k = 2 / (interval + 1);

  for (let i = 1; i < data.length; i++) {
    const prevValue = emaArray[i - 1].value;
    const currValue = data[i].value * k + prevValue * (1 - k);

    emaArray.push({ ...data[i], value: currValue });
  }

  return emaArray;
};
