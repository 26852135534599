import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  AvatarGroup,
  Avatar as JoyAvatar,
  IconButton,
  Sheet,
  Tooltip,
} from "@mui/joy";
import SettingsIcon from "@mui/icons-material/Settings";

import { useStrategyData } from "../../mock/mock.hooks";
import BacktestList from "../../Components/BacktestList";
import { useRoute } from "../../Layout/router.const";
import CredentialsDialog from "./CredentialsDialog";
import SimplePageLayout from "../../Layout/SimplePageLayout";

export default function StrategyPage() {
  const params = useParams();
  if (!params.id) throw Error();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const strategyListPageRoute = useRoute("strategies");

  const { data, isLoading } = useStrategyData(+params.id);
  if (isLoading || !data) {
    return null;
  }

  return (
    <SimplePageLayout
      title={data.name}
      breadcrumbs={[{ href: strategyListPageRoute.path, text: "Strategy" }]}
      rightTitle={
        <AvatarGroup size="sm" variant="solid">
          {data.users.map((user, i) => (
            <Tooltip key={i} title={`${user?.first_name} ${user?.last_name}`}>
              <JoyAvatar
                src={"https://avatar.iran.liara.run/public/" + user?.id}
                alt={`${user?.first_name} ${user?.last_name}`}
              />
            </Tooltip>
          ))}
          <JoyAvatar color="neutral">
            <IconButton
              onClick={() => setIsModalOpen(true)}
              variant="solid"
              sx={{
                "--IconButton-size": "32px",
              }}
            >
              <SettingsIcon />
            </IconButton>
          </JoyAvatar>
        </AvatarGroup>
      }
    >
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          height: "100%",
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        {<BacktestList data={data.backtest} />}
      </Sheet>
      <CredentialsDialog
        users={data.users.filter((x) => !!x) as any[]}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </SimplePageLayout>
  );
}
