import { Box, IconButton, Table, Typography } from "@mui/joy";

import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ViewListIcon from "@mui/icons-material/ViewList";

import SimplePageLayout from "../../Layout/SimplePageLayout";
import { useTaskList } from "../../api/backtest.api";
import { separateNumber } from "../../utils";
import MarketIcon from "../../Components/MarketIcon";
import {
  calcProgress,
  useNavigateTaskChartPage,
  useNavigateTaskPage,
} from "./maxma.utils";

export default function MaXMaPage() {
  const { data } = useTaskList();
  const navigateTaskPage = useNavigateTaskPage();
  const navigateTaskChartPage = useNavigateTaskChartPage();

  return (
    <SimplePageLayout title="MA x MA">
      <Table hoverRow>
        <thead>
          <tr>
            <th style={{ width: 50 }}>ID</th>
            <th style={{ width: 150 }}>MARKET</th>
            <th style={{ width: 64 }}>TF</th>
            <th style={{ width: 64 }}>sma(A)</th>
            <th style={{ width: 64 }}>sma(B)</th>
            <th style={{ width: 128 }}>complete</th>
            <th style={{ textAlign: "right" }}>PROFIT</th>
            <th style={{ width: 100, textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((task) => (
            <tr key={task.id}>
              <td>{task.id}</td>
              <td>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <MarketIcon symbol={task.symbol} />
                  {task.symbol}
                </Box>
              </td>
              <td>{task.timeframe}</td>
              <td>{task.smaIntervalA}</td>
              <td>{task.smaIntervalB}</td>
              <td>{`${(calcProgress(task) * 100).toFixed(1)} %`}</td>
              <td style={{ textAlign: "right" }}>
                <Typography color={task.profit < 0 ? "danger" : "success"}>
                  {separateNumber(+(task.profit * 100).toFixed(2))} %
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <IconButton onClick={() => navigateTaskPage(task.id)}>
                  <ViewListIcon />
                </IconButton>
                <IconButton onClick={() => navigateTaskChartPage(task.id)}>
                  <QueryStatsIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </SimplePageLayout>
  );
}
