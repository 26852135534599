import ApexCharts, { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import { ChartProps } from "./chart.type";
import {
  useDefaultOptions,
  useSeriesOptions,
  useShapeOptions,
} from "./chart.hooks";
import { Box } from "@mui/joy";
import Zoom from "./Zoom";
import { CHART_ZOOM_FACTOR } from "./chart.const";
import Legend from "./Legend";

export default function Chart({ spliceLeft, series, shapes }: ChartProps) {
  const [zoom, setZoom] = useState(1);

  const defaultOptions = useDefaultOptions();
  const seriesOptions = useSeriesOptions(series, spliceLeft ?? 0);
  const shapeOptions = useShapeOptions(shapes ?? []);

  useEffect(() => {
    var options: ApexOptions = {
      ...defaultOptions,
      ...seriesOptions,
      ...shapeOptions,
    };

    var chart = new ApexCharts(document.querySelector("#chart"), options);

    chart.render();

    return () => chart.destroy();
  }, [defaultOptions, seriesOptions, shapeOptions]);

  return (
    <Box sx={{ height: "calc(100% - 18px)" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Zoom onChange={setZoom} />
        <Legend series={series} />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 60px)",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            minWidth: "100%",
            width: series[0].data.length * zoom * CHART_ZOOM_FACTOR + 150,
            height: "100%",
          }}
        >
          <div id="chart" />
        </Box>
      </Box>
    </Box>
  );
}
