import { GlobalStyles, Sheet, IconButton, Box, Typography } from "@mui/joy";

import SettingsIcon from "@mui/icons-material/Settings";
import QuizIcon from "@mui/icons-material/Quiz";

import { useRoute } from "./router.const";
import { useNavigate } from "react-router-dom";
import { COMPANY_LOGO, COMPANY_NAME } from "../const";
import ThemeButton from "../Components/ThemeButton";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";

export default function Header() {
  const navigate = useNavigate();

  const homePageRoute = useRoute("home");
  const settingsPageRoute = useRoute("settings");
  const testPageRoute = useRoute("test");

  return (
    <Sheet
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "var(--Header-height)",
        zIndex: 9995,
        p: 2,
        gap: 1,
        borderBottom: "1px solid",
        borderColor: "background.level1",
        boxShadow: "sm",
      }}
    >
      <GlobalStyles
        styles={{
          ":root": {
            "--Header-height": "52px",
          },
        }}
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <IconButton
          variant="soft"
          color="primary"
          size="sm"
          sx={{ flex: 1, gap: 1, px: 1 }}
          onClick={() => navigate(homePageRoute.path)}
        >
          <COMPANY_LOGO />
          <Typography level="title-lg">{COMPANY_NAME}</Typography>
        </IconButton>
        <Breadcrumbs />
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <ThemeButton />
        <IconButton
          onClick={() => navigate(settingsPageRoute.path)}
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <SettingsIcon />
        </IconButton>
        <IconButton
          onClick={() => navigate(testPageRoute.path)}
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <QuizIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}
