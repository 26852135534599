import { Avatar, List, ListItem, ListItemContent, Typography } from "@mui/joy";
import { useSettingsData } from "../mock/mock.hooks";
import { capitalizeFirstLetter, formatDollarValue } from "../utils";
import { useRoute } from "../Layout/router.const";
import SimplePageLayout from "../Layout/SimplePageLayout";

export default function WalletsPage() {
  const settingsRoute = useRoute("settings");
  const { data } = useSettingsData();
  return (
    <SimplePageLayout
      title="Wallets"
      breadcrumbs={[
        {
          text: capitalizeFirstLetter(settingsRoute.name),
          href: settingsRoute.path,
        },
      ]}
    >
      <List>
        {data.wallet.map((wallet) => (
          <ListItem>
            <Avatar size="sm" src={wallet.imgUrl} />
            <ListItemContent>
              <Typography level="h4">{wallet.exchange}</Typography>
              <Typography level="body-sm">Lev: {wallet.leverage}</Typography>
            </ListItemContent>
            <Typography level="title-sm">
              {formatDollarValue(wallet.balance)} $
            </Typography>
          </ListItem>
        ))}
      </List>
    </SimplePageLayout>
  );
}
