import { IconButton, List, ListItem } from "@mui/joy";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Modal from "../../Components/Modal";
import UserCard from "../../Components/UserCard";

type Props = {
  open: boolean;
  onClose: () => void;
  users: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
  }[];
};

export default function CredentialsDialog(props: Props) {
  return (
    <Modal open={props.open} onClose={props.onClose} title="Credentials">
      <List sx={{ gap: 1 }}>
        {props.users.map((user) => (
          <ListItem key={user.id}>
            <UserCard id={user.id} />
            <IconButton disabled={user.role === "admin"}>
              <PersonOffIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Modal>
  );
}
