import { ApexOptions } from "apexcharts";

export const VERTICAL_CHART_OFFSET = 0.1;
export const VOLUME_CHART_HEIGHT = 0.25;

export const CHART_ZOOM_FACTOR = 4;

export const DEFAULT_CHART_OPTIONS: ApexOptions = {
  chart: {
    type: "candlestick",
    height: "100%",
    animations: {
      enabled: false,
    },
    zoom: {
      enabled: false,
    },
  },
  xaxis: {
    type: "datetime",
  },
  legend: {
    show: false,
  },
  tooltip: {
    theme: "dark",
  },
  grid: {
    position: "front",
    borderColor: "#88888888",
    strokeDashArray: 7,
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
};
