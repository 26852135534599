import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { Snackbar } from "@mui/joy";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import ErrorIcon from "@mui/icons-material/Error";

const useErrors = () => {
  const [errors, setErrors] = useState<string[]>([]);

  const addError = useCallback(
    (error: Error) => setErrors((state) => [...state, error.message]),
    [],
  );

  const clearErrors = () => setErrors([]);

  return {
    errors,
    addError,
    clearErrors,
  };
};

const QueryProvider = (props: PropsWithChildren) => {
  const { errors, addError, clearErrors } = useErrors();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({ onError: addError }),
        mutationCache: new MutationCache({ onError: addError }),
      }),
    [addError],
  );

  return (
    <QueryClientProvider client={queryClient}>
      {errors.map((error, i) => (
        <Snackbar
          key={i}
          variant="soft"
          color="danger"
          open={errors.length > 0}
          onClose={clearErrors}
        >
          <ErrorIcon />
          {error}
        </Snackbar>
      ))}
      {props.children}
    </QueryClientProvider>
  );
};

export default QueryProvider;
