export const MS_IN_MONTH = 30 * 24 * 60 * 60 * 1000;
export const MS_IN_WEEK = 7 * 24 * 60 * 60 * 1000;
export const MS_IN_DAY = 24 * 60 * 60 * 1000;
export const MS_IN_HOUR = 60 * 60 * 1000;
export const MS_IN_MINUTE = 60 * 1000;
export const MS_IN_SEC = 1000;

export const MS_IN_TIMEFRAME: Record<string, number> = {
  "1m": MS_IN_MINUTE,
  "3m": MS_IN_MINUTE * 3,
  "5m": MS_IN_MINUTE * 5,
  "15m": MS_IN_MINUTE * 15,
  "30m": MS_IN_MINUTE * 30,
  "1h": MS_IN_HOUR,
  "2h": MS_IN_HOUR * 2,
  "4h": MS_IN_HOUR * 4,
  "6h": MS_IN_HOUR * 6,
  "8h": MS_IN_HOUR * 8,
  "12h": MS_IN_HOUR * 12,
  "1d": MS_IN_DAY,
  "3d": MS_IN_DAY * 3,
  "1w": MS_IN_WEEK,
  "1M": MS_IN_MONTH,
};
