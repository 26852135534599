import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { backtestController } from "./rpc.client";
import { Timeframe } from "../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

const TASK_LIST_QUERY_KEY = "taskList";

export const useTaskList = () =>
  useSuspenseQuery({
    queryKey: [TASK_LIST_QUERY_KEY],
    queryFn: () => backtestController.getBacktestTasks({}),
    refetchInterval: 15000,
  });

type AddTaskProps = {
  symbol: string;
  tf: Timeframe;
  smaA: number;
  smaB: number;
};

export const useAddTaskMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ symbol, tf, smaA, smaB }: AddTaskProps) =>
      backtestController.addBacktestTask({
        symbol: symbol,
        timeframe: tf,
        tsStart: new Date().setDate(-365*5),
        tsEnd: Date.now(),
        strategyId: 0,
        smaIntervalA: smaA,
        smaIntervalB: smaB,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TASK_LIST_QUERY_KEY] });
    },
  });
};

export const useClearAllTask = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: backtestController.clearAll,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TASK_LIST_QUERY_KEY] });
    },
  });
};

export const useBacktestOrder = () => {
  const params = useParams();

  const orderId = useMemo(() => {
    const result = Number(params.order_id);

    return isNaN(result) ? undefined : result;
  }, [params.order_id]);

  return useSuspenseQuery({
    queryKey: ["backtest", "order", orderId],
    queryFn: async () => {
      if (!orderId) return;
      return await backtestController.getBacktestOrder(orderId);
    },
  });
};

export const useBacktestTask = (taskId: number) =>
  useSuspenseQuery({
    queryKey: ["backtest", "task", taskId],
    queryFn: async () => await backtestController.getBacktestTask(taskId),
  });
