import { AvatarGroup } from "@mui/joy";
import CoinIcon from "./CoinIcon";
import { useMarkets } from "../api/binance.spot.api";

type Props = {
  size?: React.ComponentProps<typeof CoinIcon>["size"];
  quoteAsset?: string;
  baseAsset?: string;
  symbol?: string;
};

const useMarket = (symbol?: string) => {
  const { data } = useMarkets();

  if (symbol) {
    const market = data?.find((x) => x.symbol === symbol);

    if (market && market.baseAsset && market.quoteAsset) {
      return { quoteAsset: market.quoteAsset, baseAsset: market.baseAsset };
    }
  }
};

export default function MarketIcon({
  quoteAsset,
  baseAsset,
  symbol,
  size,
}: Props) {
  const market = useMarket(symbol);
  return (
    <AvatarGroup sx={{ flexDirection: "row-reverse" }}>
      <CoinIcon coin={quoteAsset || market?.quoteAsset || "Q"} size={size} />
      <CoinIcon coin={baseAsset || market?.baseAsset || "B"} size={size} />
    </AvatarGroup>
  );
}
