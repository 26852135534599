const MA_VALUES: number[] = [];

const steps: [number, number][] = [
  [15, 1],
  [100, 5],
  [500, 10],
  [1000, 25],
  [1500, 50],
];

steps.forEach(([upperEdge, step]) => {
  const prevValue = MA_VALUES[MA_VALUES.length - 1] ?? 0;

  console.log("prevValue", prevValue);
  console.log("(upperEdge - prevValue) / step", (upperEdge - prevValue) / step);

  const newValues = [...new Array((upperEdge - prevValue) / step)]
    .map((empty, i) => i + 1)
    .map((v) => v * step + prevValue);
  MA_VALUES.push(...newValues);
});

export const MA_PAIRS: [number, number][] = [];

MA_VALUES.forEach((a, i, arr) => {
  const values = arr.slice(i).map((b) => [a, b] as [number, number]);
  values.shift();
  MA_PAIRS.push(...values);
});
