import { PropsWithChildren, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";

import ErrorBoundary from "../Components/ErrorBoundary";
import QueryProvider from "./QueryProvider";
import Spinner from "../Components/Spinner";
import Auth from "./Auth";
import { BreadcrumbsProvider } from "../Components/Breadcrumbs/BreadcrumbsContext";

export default function Configuration(props: PropsWithChildren) {
  return (
    <ErrorBoundary>
      <BreadcrumbsProvider>
        <Suspense fallback={<Spinner />}>
          <QueryProvider>
            <CssVarsProvider>
              <CssBaseline />
              <Router>
                <Auth>{props.children}</Auth>
              </Router>
            </CssVarsProvider>
          </QueryProvider>
        </Suspense>
      </BreadcrumbsProvider>
    </ErrorBoundary>
  );
}
