import { Avatar, AvatarTypeMap } from "@mui/joy";

type Props = { coin: string; size?: AvatarTypeMap["props"]["size"] };

export default function CoinIcon(props: Props) {
  return (
    <Avatar
      alt={props.coin}
      size={props.size || "sm"}
      sx={{ border: "1px solid #88888888" }}
      src={`https://cryptofonts.com/img/SVG/${props.coin.toLowerCase()}.svg`}
    />
  );
}
