import { generatePath, useNavigate } from "react-router-dom";
import { BacktestTaskEntity } from "../../../../crypto-bulls-backend/src/components/backtest/backtest.entity";
import { useRoute } from "../../Layout/router.const";

export const calcProgress = (task: BacktestTaskEntity): number => {
  if (!task.ts) return 0;

  const completeDuration = task.ts - task.tsStart;
  const fullDuration = task.tsEnd - task.tsStart;

  const complete = completeDuration / fullDuration;

  return Math.min(complete, 1);
};

export const generateTaskName = (task: BacktestTaskEntity): string =>
  `(ID: ${task.id}) ${task.symbol} ${task.timeframe} ${task.smaIntervalA}x${task.smaIntervalB}`;

export const useNavigateTaskPage = () => {
  const navigate = useNavigate();
  const task = useRoute("MA x MA - Task");

  return (taskId: number) =>
    navigate(generatePath(task.path, { task_id: taskId }));
};

export const useNavigateTaskChartPage = (): ((taskId: number) => void) => {
  const navigate = useNavigate();
  const task = useRoute("MA x MA - Chart");

  return (taskId: number) =>
    navigate(generatePath(task.path, { task_id: taskId }));
};

export const useNavigateOrderPage = () => {
  const navigate = useNavigate();
  const order = useRoute("MA x MA - Order");

  return (orderId: number) =>
    navigate(generatePath(order.path, { order_id: orderId }));
};
