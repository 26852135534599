import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/inter";

import App from "./Layout/App";
import Configuration from "./Layout/Configuration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Configuration>
      <App />
    </Configuration>
  </React.StrictMode>,
);
