import { Route, Routes } from "react-router-dom";
import { routes } from "./router.const";
import Redirect from "./Redirect";

export default function Router() {
  return (
    <Routes>
      {routes.map((r) => (
        <Route
          key={r.name}
          path={r.path}
          element={r.redirect ? <Redirect to={r.redirect} /> : <r.element />}
        />
      ))}
      <Route path="*" element={<div>404</div>} />
    </Routes>
  );
}
