import { Suspense } from "react";
import { Box } from "@mui/joy";

import Header from "./Header";
import Router from "./Router";
import Spinner from "../Components/Spinner";

const App = () => {
  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Header />
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: 2,
          pt: "calc(12px + var(--Header-height))",
          pb: 2,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Suspense fallback={<Spinner />}>
          <Router />
        </Suspense>
      </Box>
    </Box>
  );
};

export default App;
