import { Link } from "@mui/joy";
import SimplePageLayout from "../Layout/SimplePageLayout";
import { useRoute } from "../Layout/router.const";

export default function HomePage() {
  const maxmaRoute = useRoute("MA x MA");
  return (
    <SimplePageLayout title="Home">
      <Link href={maxmaRoute.path}>MA x MA</Link>
    </SimplePageLayout>
  );
}
