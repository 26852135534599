import { random } from "../utils";

let iterationId = 1;
const getRandomResult = () => (random(2000) - 1000) / 100;
const getUser = (id: number) => USERS_MOCK.find((user) => user.id === id);

export const USERS_MOCK = [
  {
    id: 1,
    first_name: "Yancy",
    last_name: "Figures",
    email: "yfigures0@adobe.com",
    role: "admin",
  },
  {
    id: 2,
    first_name: "Zsazsa",
    last_name: "MacMechan",
    email: "zmacmechan1@census.gov",
    role: "admin",
  },
  {
    id: 3,
    first_name: "Gallagher",
    last_name: "Dumelow",
    email: "gdumelow2@admin.ch",
    role: "collaborator",
  },
  {
    id: 4,
    first_name: "Codie",
    last_name: "Tomes",
    email: "ctomes3@toplist.cz",
    role: "collaborator",
  },
  {
    id: 5,
    first_name: "Lenee",
    last_name: "Leer",
    email: "lleer4@geocities.com",
    role: "collaborator",
  },
  {
    id: 6,
    first_name: "Keely",
    last_name: "Itzkov",
    email: "kitzkov5@vinaora.com",
    role: "collaborator",
  },
  {
    id: 7,
    first_name: "Addy",
    last_name: "Abrahim",
    email: "aabrahim6@godaddy.com",
    role: "collaborator",
  },
  {
    id: 8,
    first_name: "Yovonnda",
    last_name: "Dreng",
    email: "ydreng7@java.com",
    role: "collaborator",
  },
  {
    id: 9,
    first_name: "Loutitia",
    last_name: "Groome",
    email: "lgroome8@devhub.com",
    role: "guest",
  },
  {
    id: 10,
    first_name: "Aprilette",
    last_name: "Doodney",
    email: "adoodney9@facebook.com",
    role: "guest",
  },
];

export const STRATEGY_LIST_MOCK = [
  {
    id: 1,
    name: "MA x MA",
    users: [getUser(1), getUser(2), getUser(5)].filter((x) => !!x),
    backtest: [
      {
        id: iterationId++,
        name: "D: 7 x 24",
        progress: 1,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "D: 3 x 9",
        progress: 1,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "D: 4 x 19",
        progress: 1,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: 12 x 48",
        progress: 1,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: 4 x 24",
        progress: 0.77,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "m: 5 x 5000",
        progress: 0,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "D: 15 x 1000",
        progress: 0,
        profit: getRandomResult(),
      },
    ],
  },
  {
    id: 2,
    name: "Fibo_X",
    users: [getUser(1), getUser(2), getUser(4)].filter((x) => !!x),

    backtest: [
      {
        id: iterationId++,
        name: "D: big interval",
        progress: 0.11,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "D: med interval",
        progress: 0,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "D: sml interval",
        progress: 0,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: big interval",
        progress: 0,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: big interval",
        progress: 0,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: big interval",
        progress: 0,
        profit: getRandomResult(),
      },
    ],
  },
  {
    id: 3,
    name: "Fibo x F&G",
    users: [getUser(1), getUser(2), getUser(3), getUser(7), getUser(8)].filter(
      (x) => !!x,
    ),
    backtest: [
      {
        id: iterationId++,
        name: "D: big interval",
        progress: 1,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "D: med interval",
        progress: 1,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "D: sml interval",
        progress: 0.99,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: big interval",
        progress: 0,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: big interval",
        progress: 0,
        profit: getRandomResult(),
      },
      {
        id: iterationId++,
        name: "H: big interval",
        progress: 0,
        profit: getRandomResult(),
      },
    ],
  },
];

export const SETTINGS_MOCK = {
  wallet: [
    {
      exchange: "Binance",
      imgUrl: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png",
      leverage: 5,
      balance: random(30000),
      strategy: {
        name: "Fibo",
      },
    },
    {
      exchange: "ByBit",
      imgUrl: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/521.png",
      leverage: 20,
      balance: random(30000),
      strategy: {
        name: "MAxMA",
      },
    },
    {
      exchange: "OKX",
      imgUrl: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/294.png",
      leverage: 50,
      balance: random(30000),
      strategy: {
        name: "NewsAI",
      },
    },
    {
      exchange: "Kraken",
      imgUrl: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/24.png",
      leverage: 3,
      balance: random(30000),
      strategy: {
        name: "F&G",
      },
    },
  ],
};
