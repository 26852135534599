import { List, ListItemButton, Typography } from "@mui/joy";
import { capitalizeFirstLetter } from "../utils";
import { useNavigate } from "react-router-dom";
import { useRoute } from "../Layout/router.const";
import SimplePageLayout from "../Layout/SimplePageLayout";

export default function SettingsPage() {
  const navigate = useNavigate();

  const walletsRoute = useRoute("wallets");
  const usersRoute = useRoute("users");

  return (
    <SimplePageLayout title={"Settings"}>
      <List>
        <ListItemButton onClick={() => navigate(walletsRoute.path)}>
          <Typography level="title-lg">
            {capitalizeFirstLetter(walletsRoute.name)}
          </Typography>
        </ListItemButton>
        <ListItemButton onClick={() => navigate(usersRoute.path)}>
          <Typography level="title-lg">
            {capitalizeFirstLetter(usersRoute.name)}
          </Typography>
        </ListItemButton>
      </List>
    </SimplePageLayout>
  );
}
