import { useState } from "react";
import { Button, ButtonGroup, IconButton } from "@mui/joy";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const DEFAULT_VALUE = 1;
const OPTIONS = [0.25, 0.5, 0.75, 0.9, 1, 1.1, 1.25, 1.5, 2.0, 3.0, 4.0];

type Props = {
  onChange: (value: number) => void;
};

export default function Zoom({ onChange }: Props) {
  const [value, setValue] = useState(DEFAULT_VALUE);
  return (
    <ButtonGroup>
      <IconButton
        onClick={() => {
          const index = OPTIONS.indexOf(value);
          const nextIndex = Math.max(index - 1, 0);
          const nextValue = OPTIONS[nextIndex];
          setValue(nextValue);
          onChange(nextValue);
        }}
      >
        <RemoveIcon />
      </IconButton>
      <Button onClick={() => onChange(DEFAULT_VALUE)}>
        {(value * 100).toFixed(0)}%
      </Button>
      <IconButton
        onClick={() => {
          const index = OPTIONS.indexOf(value);
          const nextIndex = Math.min(index + 1, OPTIONS.length - 1);
          const nextValue = OPTIONS[nextIndex];
          setValue(nextValue);
          onChange(nextValue);
        }}
      >
        <AddIcon />
      </IconButton>
    </ButtonGroup>
  );
}
