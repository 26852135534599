/**
 * `await delay(666)` для создания ожидания на 666мс
 */
export const delay = (ms: number): Promise<number> => {
  if (ms <= 0) {
    return Promise.resolve(ms);
  }

  return new Promise((resolve) => {
    setTimeout(() => resolve(ms), ms);
  });
};

/**
 * создает мок-функцию симулирующую обращение к серверу
 */
export const createMockRequest =
  <T = any>(dataFactory: () => T, delayMs?: number) =>
  async () => {
    await delay((delayMs || 1000) + random(2000));
    return dataFactory();
  };

/**
 * Гененрирует рандомное число от 0 до max
 */
export const random = (max = 1000000) => Math.floor(Math.random() * max);

export const fixJsFloat = (value: number) => {
  return +value.toPrecision(15);
};

export function separateNumber(x: number) {
  return ("" + x).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const formatDate = (ts: number) => {
  const d = new Date(ts);
  const twoDigits = (value: number) => {
    const str = "" + value;
    if (str.length < 2) return "0" + str;
    return str;
  };

  const date = `${twoDigits(d.getUTCDate())}.${twoDigits(d.getUTCMonth() + 1)}.${d.getFullYear()}`;
  const time = `${twoDigits(d.getUTCHours())}:${twoDigits(d.getUTCMinutes())}`;

  return date + (time === "00:00" ? "" : ` ${time}`);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDollarValue = (value: number) =>
  ("" + value)
    .split("")
    .reverse()
    .join("")
    .replace(/([0-9]{3})/g, "$1 ")
    .split("")
    .reverse()
    .join("");
