import { useQuery } from "@tanstack/react-query";
import { Timeframe } from "../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { historyController, marketController } from "./rpc.client";

export const getHistory = (
  market: string,
  tf: Timeframe,
  from: number,
  to: number,
) => historyController.getHistory(market, tf, from, to);

export const useBtcPriceCandleHistory = (limit = 200) => {
  const now = new Date();
  const from = new Date().setDate(now.getDate() - limit);

  return useQuery({
    queryKey: ["binance", "spot", "btc"],
    queryFn: () => getHistory("BTCUSDT", "1d", +from, +now),
  });
};

export const useMarketHistory = (
  symbol: string,
  tf: Timeframe,
  from: number,
  to: number,
) =>
  useQuery({
    queryKey: ["market_history", symbol, tf, from, to],
    queryFn: () => getHistory(symbol, tf, from, to),
  });

export const useMarkets = () =>
  useQuery({
    queryKey: ["marketList"],
    queryFn: marketController.getMarkets,
  });
