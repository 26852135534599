import { RpcWebSocketClient } from "@deepkit/rpc";
import { API_URL } from "../const";
import type { HistoryControllerRpc } from "../../../crypto-bulls-backend/src/components/history/history.controller.rpc";
import type { FgiControllerRpc } from "../../../crypto-bulls-backend/src/components/fgi/fgi.controller.rpc";
import type { MarketControllerRpc } from "../../../crypto-bulls-backend/src/components/market/market.controller.rpc";
import type { BacktestControllerRpc } from "../../../crypto-bulls-backend/src/components/backtest/backtest.controller.rpc";

const client = new RpcWebSocketClient(API_URL);

export const historyController =
  client.controller<HistoryControllerRpc>("/history/binance");

export const fgiController =
  client.controller<FgiControllerRpc>("/history/fgi");

export const marketController =
  client.controller<MarketControllerRpc>("/market");

export const backtestController =
  client.controller<BacktestControllerRpc>("/backtest");
