import {
  PropsWithChildren,
  ReactNode,
  Suspense,
  useContext,
  useEffect,
} from "react";
import { Box, Sheet, Typography } from "@mui/joy";
import Spinner from "../Components/Spinner";
import {
  BreadcrumbsContext,
  BreadcrumbsItem,
} from "../Components/Breadcrumbs/BreadcrumbsContext";

type Props = {
  title?: string;
  breadcrumbs?: BreadcrumbsItem[];
  contentPadding?: number;
  rightTitle?: ReactNode;
  bottomTitle?: ReactNode;
};

export default function SimplePageLayout(props: PropsWithChildren<Props>) {
  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  useEffect(() => {
    const newBreadcrumbs: BreadcrumbsItem[] = [];
    if (props.breadcrumbs) newBreadcrumbs.push(...props.breadcrumbs);
    if (props.title) newBreadcrumbs.push({ text: props.title });

    setBreadcrumbs(newBreadcrumbs);
  }, [props.breadcrumbs, props.title, setBreadcrumbs]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.title && <Typography level="h4">{props.title}</Typography>}
        {props.rightTitle}
      </Box>
      <Sheet
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          p: props.contentPadding ?? 2,
        }}
      >
        <Suspense fallback={<Spinner />}>{props.children}</Suspense>
      </Sheet>
      {props.bottomTitle}
    </>
  );
}
